@charset "UTF-8";
.footer-banner-area img {
    width: 30vw;
    max-width: 300px;
    min-width: 180px;
    @include sp{
        width: 70vw;
        max-width: 70vw;
        min-width: 70vw;
    }
}

.footer-logo-area img {
    margin: 0 2rem 0 0 !important;
    width: 10vw;
    max-width: 150px;
    min-width: 50px;
    height: 100%;
    @include sp{
        margin: 0 0 2rem 0 !important;
        width: 40vw;
        max-width: 40vw;
        min-width: 40vw;
    }
}

.footer-links{
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
    @include sp{
        flex-direction: column;
    }
}