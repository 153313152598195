@charset "UTF-8";
.second-item {
    display: block;
    padding: .6rem ;
    color: #ffffff;
    background-color: #004098;
    transition: .3s;
    cursor: pointer;
    @include sp{
        font-size: 3.8vw;
        width: 100%;
        color: #565656;
        background-color: #ffffff !important;
    }
    @include sp-min {
        font-size: 1.4rem;
    }
    &:hover {
        color: #ffffff !important;
        background-color: #1f67cc;
        transition: .3s;
        // & + a.navbar-item {
        //     display: block !important;
        // }
    }
    &__wrap {
        position: absolute;
        z-index: 999;
        color: #ffffff;
        background-color: #004098;
        @include sp {
            display: none !important;
            position: relative;
            color: #004098;
            background-color: #ffffff !important;
        }
        &:hover {
            display: block !important;
        }
        &.is-active{
            display: block !important;
        }
    }
}
.navbar {
    // position: fixed;
    width: 100%;
    padding: 0 0 0 6rem;
    margin: 0 !important;
    @include sp{
        padding: 0 6rem;
    }
}
.nav-toggle-btn {
    cursor: default !important;
    &:hover{
        opacity: 1 !important;
    }
    @include sp{
        cursor: pointer !important;
    }
}

#index-header .navbar-brand {
    padding: 0 6rem;
    top:0 !important;
    @include sp{
        padding: 0; 
    }
}
.front-logo {
    &.is-active{
        img {
            width: 60px !important;
            @include sp{
                width: 50px !important;
            }
        }
    }
}
header #navbarMenuHeroC a.navbar-item {
    color: #004098;
    white-space: normal;
    padding: 1.2rem .8rem; 
    background-color: #ffffff;
    display: block !important;
    margin: 0 auto;
    transition: .3s;
    height: 100%;
    cursor: pointer;
    @include sp{
        font-size: 5.2vw;
        max-width: auto;
        padding: .8rem;
        background-color: #004098 !important;
        color: #ffffff;
    }
    @include sp-min {
        font-size: 1.4rem;
    }
    &.is-over{
        background-color: #004098 !important;
        color: #ffffff;
    }
    &:hover {
        // opacity: .7;
        transition: 86ms;
        background-color: #004098 !important;
        color: #ffffff;
        height: 100%;
        display: inline-block;
        @include sp{
            opacity: 1;
            background-color: #004098;
        }
        // & + .second-item__wrap {
        //     display: block !important;
        // }
    }
    &__icon{
        position: relative;
        &:before {
            @include sp{
                position: absolute;
                content: "";
                top: 50%;
                right: 20px;
                width: .8rem;
                height: .8rem;
                border-top: 1px solid #ffffff;
                border-right: 1px solid #ffffff;
                transform: translateY(-50%) rotate(45deg);
            }
        }
    }
}
.navbar-item {
    
    &__main, &__sub{
        display: block;
    }
    &__main {
        font-size: .5rem;
        line-height: .8rem;
        @include sp{
            font-size: 1.5rem;
            font-weight: 500;
            line-height: inherit;
        }
        
    }
    &__sub{
        font-size: 0.8rem;
        line-height: 1.2rem;
        font-weight: 700;
        @include sp{
            font-size: .5rem;
            line-height: inherit;
        }
    }
}
.navbar-end {
    display: block;
    // height: 100%;
    width: 100%;
    z-index: 9998;
    background: #ffffff;
    // padding: 100px 6rem;
    display: flex;
    flex-flow: wrap;
    z-index: 99;
    min-height: 4.5rem;
    @include pc{
        text-align: left !important;
    }
    @include sp{
        position: fixed;
        left: 0;
        top:0;
        padding: 5rem 2rem ;
        overflow: scroll; 
        display: block;
        height: 100%;
    }
    & > * {
        // width: calc(100% / 3);
        // @include pc {
        //     width: calc(100% / 2);
        // }
        @include sp{
            width: 100%;
        }
    }
}
.navbar-menu {
    flex-grow: 1;
    flex-shrink: 0;
    background: #ffffff;
    height: 100%;
    // width: 100%;
    box-sizing: border-box;
    @include sp {
        display: none;
    }
    &.is-active{ 
        @include sp {
            display: block; 
        }
    }
}
.navbar-burger {
    display: none;
    position: relative;
    margin-right: 0;
    height: 4.4rem;
    z-index: 100;
    @include sp {
        display: block;
    }
}
.navbar-brand {
    flex-flow: wrap;
    // width: 100%;
    position: relative;
    z-index: 9999;
    @include sp{
        width: 100%;
    }
}
.nav-toggle{
    &__icon {
        position: relative;
        &:before, &:after{
            @include sp{
                position: absolute;
                content: "";
                right:15px;
                top: 50%;
                transform: translateY(-50%);
                width: 1rem;
                height: 1px;
                background-color: #ffffff;
            }
        }
        &:before{
            @include sp{
                transform: translateY(-50%) rotate(90deg);
                transition: 86ms;
            }
        }
        &.is-active {
            &:before{
                @include sp{
                    transform: translateY(-50%) rotate(0);
                    opacity: 0;
                    transition: 86ms;
                }
            }
        }
    }
}

.header-logo-area {
    position: relative;
    z-index: 100;
}

.nav-item{
    &__wrap {
        @include sp{
            border-bottom: 1px solid #ffffff;
        }
    }
}

html {
    &.is-fixed{
        position: relative;
        overflow: hidden !important;
    }
}
body {
    &.is-fixed{
        position: relative;
        overflow: hidden !important;
    }
}
.hero {
    z-index: 29;
}