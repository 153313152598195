@charset "UTF-8";

.column {
  &__marginTop {
    margin-top: 40px;
    @include sp {
      margin-top: 4rem;
    }
  }
  &__logo {
    width: 170px;
    min-height: 60px;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    @include sp {
      margin: 0 auto 20px auto;
      max-width: 60%;
    }
    img {
      width: 120px !important;
      margin: 0 auto;
    }
    &--small01 {
      img {
        width: 75px !important;
      }
    }
    &--small02 {
      img {
        width: 90px !important;
      }
    }
    &--big01 {
      width: 180px;
      img {
        width: 160px !important;
      }
    }
    &--big02 {
      img {
        width: 165px !important;
      }
    }
    &--axa {
      img {
        width: 130px !important;
      }
    }
    &--axadilect {
      img {
        width: 145px !important;
      }
    }
    &--nn {
      img {
        width: 160px !important;
      }
    }
    &--fwd {
      img {
        width: 98px !important;
      }
    }
    &--orix {
      img {
        width: 148px !important;
      }
    }
    &--gib {
      img {
        width: 132px !important;
      }
    }
    &--sony {
      img {
        width: 130px !important;
      }
    }
    &--somp {
      img {
        width: 140px !important;
      }
    }
    &--frontier {
      img {
        width: 140px !important;
      }
    }
    &--zurich {
      img {
        width: 100px !important;
      }
    }
    &--zurich {
      img {
        width: 100px !important;
      }
    }
    &--seemee {
      img {
        width: 150px !important;
      }
    }
    &--nanairo {
      img {
        width: 150px !important;
      }
    }
    &--nihonseimei {
      img {
        width: 135px !important;
      }
    }
    &--neofirst {
      img {
        width: 130px !important;
      }
    }
    &--hanasaku {
      img {
        width: 145px !important;
      }
    }
    &--manulife {
      img {
        width: 160px !important;
      }
    }
    &--mitsui {
      img {
        width: 150px !important;
      }
    }
    &--meijiyasuda {
      img {
        width: 140px !important;
      }
    }
    &--metlife {
      img {
        width: 160px !important;
      }
    }
    &--medicare {
      img {
        width: 130px !important;
      }
    }
    &--aioinissei {
      img {
        width: 145px !important;
      }
    }
    &--mitsuikaijyo {
      img {
        width: 150px !important;
      }
    }
    &--tokyokaijjyo {
      img {
        width: 145px !important;
      }
    }
    &--sonpojapan {
      img {
        width: 165px !important;
      }
    }
    &--ps {
      img {
        width: 130px !important;
      }
    }
  }
  &__item {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    @include sp {
      display: block;
    }
  }
}
