.table {
  &__title {
    @include sp {
      display: block;
    }
  }
  &__title02 {
    display: block;
  }
  &__list {
    &__title {
      width: 100px;
      display: inline-block;
    }
  }
  &-mb {
    margin-bottom: 120px !important;
    @include sp {
      margin-bottom: 3rem !important;
    }
  }
}
