@charset "UTF-8";
.pc-on {
  display: block; }

.sp-on {
  display: none; }

@media screen and (max-width: 768px) {
  .pc-on {
    display: none; }
  .sp-on {
    display: block; } }

/* ---------------------- common ---------------------- */
body {
  font-family: "Sawarabi Gothic";
  color: #565656; }

/* title */
.title {
  font-weight: normal; }

/* font */
.font-hind {
  font-family: 'Hind', sans-serif; }

.text-area {
  letter-spacing: .5px;
  line-height: 1.8; }

.subtitle {
  line-height: 3.25;
  font-style: italic; }

h3.subtitle-02 {
  position: relative;
  display: inline-block;
  margin-bottom: 3rem; }

h3.subtitle-02:before {
  content: "";
  position: absolute;
  bottom: -25px;
  display: inline-block;
  width: 60px;
  height: 2px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #004098; }

h3.subtitle-03 {
  position: relative;
  display: inline-block;
  margin-bottom: 4rem;
  font-weight: normal; }

h3.subtitle-03:before {
  content: "";
  position: absolute;
  bottom: -25px;
  display: inline-block;
  width: 60px;
  height: 2px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #004098; }

h3.bg-gradient, h4.bg-gradient {
  color: #fff !important;
  font-size: 1rem;
  font-weight: normal;
  padding: 1rem; }

.content h3 {
  color: #565656;
  font-weight: normal; }

.content h4 {
  font-weight: normal;
  border-bottom: 1px solid #cfcfcf;
  padding-bottom: .3rem;
  line-height: 1.6; }

.content h5 {
  font-weight: normal;
  font-size: 1rem;
  margin-bottom: .5em; }

@media screen and (max-width: 768px) {
  h3.subtitle-02 {
    margin-bottom: 5rem; } }

@media screen and (max-width: 414px) {
  h3.subtitle-02:before {
    bottom: -15px; }
  .content h3 {
    line-height: 1.8; } }

/* font-color */
.font-color-01 {
  color: #0081cd !important; }

.font-color-02 {
  color: #004098 !important; }

/* button */
.button {
  border-color: #0081cd;
  border-radius: 0;
  color: #0081cd;
  transition: .3s; }

.button.is-hovered, .button:hover {
  border-color: #004098;
  color: #004098; }

.cv-button {
  background-color: #004098;
  color: #fff;
  transition: .3s; }

.cv-button:hover {
  background-color: #fff;
  color: #004098; }

/* pagetop-button */
#goTop {
  background-color: #004098;
  position: fixed;
  right: 0;
  bottom: 0;
  text-align: center;
  vertical-align: middle;
  width: 50px;
  height: 50px;
  z-index: 999;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  transition: .3s; }

#goTop img {
  width: 36px; }

#goTop:hover {
  opacity: .8; }

#goTop a {
  color: #fff;
  display: block;
  line-height: 3.8; }

/* bg */
.bg-gradient {
  background: #0074c3;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #0074c3 0%, #0089d4 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #0074c3 0%, #0089d4 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #0074c3 0%, #0089d4 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0074c3', endColorstr='#0089d4', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */ }

.bg-supplement-area-01 {
  background-color: #ebf8ff;
  padding: 3rem; }

@media screen and (max-width: 414px) {
  .bg-supplement-area-01 {
    padding: 1.5rem; } }

/* layout */
.atten {
  margin: 30px 0; }

.pt15 {
  padding-top: 1.5rem; }

.columns {
  margin: 0; }

.section, #contact .inner {
  padding: 6rem 4rem; }

.content ul {
  list-style: none; }

.sub-conts-area {
  width: 980px;
  margin: 0 auto; }

.content.sub-conts-area-inner {
  margin-bottom: 5.5rem; }

@media screen and (max-width: 768px) {
  .section, #contact .inner {
    padding: 4rem 2.75rem; } }

@media screen and (max-width: 414px) {
  .section, #contact .inner {
    padding: 4rem .75rem; }
  .is-hidden-newsp {
    display: hidden !important; } }

@media screen and (max-width: 1024px) {
  .sub-conts-area {
    width: 100%;
    margin: 0; } }

/* table */
.table {
  color: #565656; }

.table th, .table td {
  padding: 2em 1.75em !important; }

.content .table th, .content .table td {
  padding: 1em 1.75em !important; }

.table th {
  width: 20%;
  border-bottom: 2px solid #004098 !important;
  font-weight: normal; }

.table td {
  border-bottom: 2px solid #cfcfcf !important; }

@media screen and (max-width: 768px) {
  .table th {
    width: 100%;
    border: none !important;
    padding: 1.5em .75em 0 !important; }
  .table tr:first-of-type th {
    padding-top: 0 !important; }
  .table td {
    padding: .75em .75em 1.5em !important; } }

/* humbeger-menu */
.navbar-burger {
  color: #004098; }

/* g-nav */
header.navbar {
  font-size: .875rem;
  min-height: 4.5rem;
  margin: 0 6rem; }

.navbar-link.is-active, a.navbar-item.is-active {
  color: #565656; }

header a.navbar-item {
  color: #565656;
  transition: .3s; }

a.navbar-item:hover {
  color: #fff;
  background-color: #004098; }

@media screen and (max-width: 1024px) {
  header.navbar {
    padding: 0 3rem;
    margin: 0; }
  header a.navbar-item {
    white-space: nowrap; } }

@media screen and (max-width: 1023px) {
  .navbar-menu.is-active {
    display: block;
    height: calc(100vh - 70px);
    box-sizing: border-box;
    overflow: auto;
    padding-bottom: 70px; } }

@media screen and (max-width: 834px) {
  .hero-head {
    position: fixed;
    background-color: #fff;
    width: 100%;
    z-index: 999; }
  header.navbar {
    padding: 0 2.75rem;
    min-height: 3.5rem; }
  #navbarMenuHeroC .navbar-end {
    text-align: center; }
  header #navbarMenuHeroC a.navbar-item {
    font-size: 5.2vw;
    color: #004098;
    white-space: normal;
    padding: .8rem; }
  .navbar-menu {
    background-color: initial;
    box-shadow: 0 8px 16px rgba(10, 10, 10, 0); }
  #navbarMenuHeroC .navbar-link.is-active, #navbarMenuHeroC .navbar-link:focus, #navbarMenuHeroC .navbar-link:focus-within, #navbarMenuHeroC .navbar-link:hover, #navbarMenuHeroC a.navbar-item.is-active, #navbarMenuHeroC a.navbar-item:focus, #navbarMenuHeroC a.navbar-item:focus-within, #navbarMenuHeroC a.navbar-item:hover {
    background-color: initial; } }

@media screen and (max-width: 768px) {
  header.navbar {
    padding: 0 2.75rem; }
  #index-hero .hero-head h1 img {
    padding-top: 5px; } }

@media screen and (max-width: 414px) {
  header.navbar {
    padding: 0 .75rem; }
  header #navbarMenuHeroC a.navbar-item {
    font-size: 1.4rem; } }

/* footer */
.footer nav a:hover {
  color: #004098 !important; }

/* ---------------------- 下層common ---------------------- */
/* header */
#sub-header h1 img {
  width: 60px; }

#sub-header .navbar-item img {
  max-height: initial; }

@media screen and (max-width: 768px) {
  #sub-hero .hero-body {
    margin-top: 56px; }
  #sub-header {
    position: fixed;
    background-color: #fff;
    width: 100%;
    z-index: 999; }
  #sub-header h1 img {
    width: 50px;
    padding-top: 5px; } }

/* hero */
#sub-hero h2 {
  color: #fff;
  letter-spacing: .1em; }

@media screen and (max-width: 768px) {
  #sub-hero .hero-body {
    padding: 8rem 1.5rem; } }

@media screen and (max-width: 414px) {
  .title.is-2 {
    font-size: 2rem; } }

/* breadcrumb */
.breadcrumb {
  background-color: #ebf8ff;
  padding: 1rem 0; }

.breadcrumb ul {
  justify-content: center; }

.breadcrumb li + li::before {
  color: #565656; }

.breadcrumb:not(:last-child) {
  margin-bottom: 0; }

/* ---------------------- Top ---------------------- */
/* ---------------------- hero ---------------------- */
.hero {
  position: relative; }

#index-hero .hero-body {
  /*background: url("../img/bg-mv-pc.jpg") center center no-repeat;*/
  background-size: cover; }

/*qd*/
#index-hero .hero-body {
  /*
	background: url("../img/bg-mv-pc.jpg") center center no-repeat;
 background: url("../img/pc_cm_back.png") center center no-repeat;
 */
  background: url("../img/pc_01_back.png") center center no-repeat;
  /*background-size: auto calc(100% - 325px);
 background-position: center calc(100% - 110px);*/
  background-size: auto 100%;
  background-position: center 100%;
  opacity: 0;
  -moz-transition: 0.8s ease-in-out;
  -o-transition: 0.8s ease-in-out;
  -webkit-transition: 0.8s ease-in-out;
  transition: 0.8s ease-in-out;
  position: relative;
  z-index: 1; }

.w_horizon #index-hero .hero-body {
  /*background-size: auto calc(100% - 113px);
 background-position: center calc(100% - 50px);*/
  background-size: auto 100%;
  background-position: center 100%; }

#index-hero .hero-body .cover_link {
  position: absolute;
  z-index: 2;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.hero-head {
  background-color: #fff; }

#index-hero .hero-body.is-active {
  opacity: 1; }

.hero.is-fullheight {
  background-image: url(/recruit/lp01/assets/img/top/mv_bg_lg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }

.top_logo {
  position: absolute;
  top: 48vh;
  left: 50%;
  max-width: 570px;
  -webkit-transform: translate(-50%, -25vh);
  transform: translate(-50%, -25vh);
  z-index: 1; }

.top_eiji {
  position: absolute;
  right: -50%;
  bottom: -4%;
  width: 100%; }

.top_logo .ap_img {
  height: 44vh;
  width: auto; }

.top_copy {
  display: flex;
  position: absolute;
  top: 65px;
  left: 75.7%;
  height: 192px;
  z-index: 1; }

.top_copy:before, .top_copy:after {
  content: "";
  display: block;
  height: 0;
  -webkit-transform-origin: top;
  transform-origin: top;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover; }

.top_copy:before {
  width: 62px;
  height: 0;
  background-image: url(/recruit/lp01/assets/img/top/top_01.png); }

.top_copy span {
  overflow: hidden;
  width: 78px;
  height: 0;
  -webkit-transform-origin: top;
  transform-origin: top;
  background-image: url(/recruit/lp01/assets/img/top/top_02.png);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  text-indent: -9999px; }

.top_copy:after {
  width: 83px;
  background-image: url(/recruit/lp01/assets/img/top/top_03.png); }

.top_copy.is-active:before {
  -webkit-animation: textFade 0.3s cubic-bezier(0, 0.65, 0.67, 1) 0.8s 1 normal both;
  animation: textFade 0.3s cubic-bezier(0, 0.65, 0.67, 1) 0.8s 1 normal both; }

.top_copy.is-active span {
  -webkit-animation: textFade 0.3s cubic-bezier(0, 0.65, 0.67, 1) 0.4s 1 normal both;
  animation: textFade 0.3s cubic-bezier(0, 0.65, 0.67, 1) 0.4s 1 normal both; }

.top_copy.is-active:after {
  -webkit-animation: textFade 0.3s cubic-bezier(0, 0.65, 0.67, 1) 0s 1 normal both;
  animation: textFade 0.3s cubic-bezier(0, 0.65, 0.67, 1) 0s 1 normal both; }

@-webkit-keyframes textFade {
  0% {
    height: 0; }
  100% {
    height: 100%; } }

@keyframes textFade {
  0% {
    height: 0; }
  100% {
    height: 100%; } }

/*--qd*/
@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, #index-hero .hero-body {
    height: 100vh; } }

/* cm banner*/
.cm-banner {
  position: absolute;
  bottom: 2vh;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 0 1.2em;
  z-index: 5; }

.cm-banner img {
  width: 80%;
  max-width: 860px; }

/* logo */
#index-header .navbar-brand {
  position: absolute;
  top: 10%;
  left: 0; }

#index-header .navbar-item img {
  max-height: initial !important; }

.navbar-item h1 img {
  max-height: none; }

.header-logo-area {
  padding: 0; }

#index-header .header-logo-area h1 {
  background-color: #fff;
  padding: 1.5rem;
  text-align: center; }

#index-header .header-logo-area img {
  width: 130px; }

@media screen and (max-width: 1024px) {
  /*qd*/
  .w_horizon #index-hero .hero-body {
    background-size: contain; }
  .w_square #index-hero .hero-body {
    background-size: auto calc(100% - 2vh); }
  .w_square .top_eiji {
    top: 22vh; }
  .w_square .top_logo .ap_img {
    width: auto;
    height: 48vh; }
  .top_copy {
    top: 45px;
    right: 4vw;
    left: auto; }
  .top_logo .ap_img {
    width: 50vw;
    height: auto; }
  .top_eiji {
    width: 90%;
    right: -40%;
    bottom: -23%; }
  #index-hero .hero-body {
    background-image: url(../img/sm_02_back.png);
    /*background-image: url(../img/sm_cm_back.png);*/
    background-size: auto calc(100% - 200px);
    /*background-position: center calc(100% - 5vh);*/
    background-position: center 100%; }
  .w_square #index-hero .hero-body {
    background-size: auto calc(100% - 134px);
    background-position: center bottom; }
  /*--qd*/
  #index-header .navbar-brand {
    position: initial; }
  #index-header .header-logo-area h1 {
    background-color: inherit;
    padding: 0;
    text-align: left; }
  #index-header .header-logo-area img {
    width: 50px; }
  .navbar-burger {
    height: 4.4rem; } }

.catchcopy {
  width: 30%; }

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .hero-body .container.has-text-centered {
    text-align: inherit !important; } }

@media screen and (max-width: 1240px) {
  .top_copy {
    height: 134px;
    top: 40px; }
  .top_copy:before {
    width: 43px; }
  .top_copy span {
    overflow: hidden;
    width: 54px; }
  .top_copy:after {
    width: 58px; }
  #index-hero .hero-body {
    background-size: auto calc(100% - 90px); }
  /*1240*/ }

@media screen and (max-width: 1024px) {
  .catchcopy {
    width: 50%; }
  /*1024*/ }

@media screen and (max-width: 834px) {
  .top_copy {
    top: 90px; }
  .top_logo {
    top: 60vh; }
  .w_square #index-hero .hero-body {
    background-size: auto calc(100% - 120px);
    background-position: 6vw center; }
  /*834*/ }

@media screen and (max-width: 769px) {
  /*qd*/
  .top_copy {
    top: 100px; }
  /*768*/ }

@media screen and (max-width: 520px) {
  .h_long .top_logo {
    top: 50vh; }
  #index-hero .hero-body {
    background-size: 106% auto; }
  .top_logo {
    top: 61vh; }
  .sm_ip .cm-banner {
    bottom: calc(49px + 4vh); }
  #index-hero .hero-body {
    background-position: center 200px; }
  /*520*/ }

@media screen and (max-width: 414px) {
  .navbar-burger {
    height: 3.25rem; }
  .catchcopy {
    width: 70%; }
  /*qd*/
  .top_copy {
    top: 75px; }
  .top_copy:before {
    width: 34px; }
  .top_copy span {
    overflow: hidden;
    width: 43px; }
  .top_copy:after {
    width: 46px; }
  .top_copy {
    height: 107px; }
  #index-hero .hero-body {
    background-position: center 163px; }
  .top_logo {
    top: 66vh; }
  .h_long .top_logo {
    top: 59vh; }
  /*--qd*/ }

/* scrolldown */
.arrowWrap {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 168px; }

.arrowInner p {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  font-style: italic;
  text-align: end;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  margin-right: 1px;
  letter-spacing: .15em; }

.arrow {
  width: 1px;
  height: 100px;
  margin: 50px auto 0;
  background-color: #004098;
  position: relative;
  overflow: hidden; }

.arrow::before {
  content: "";
  width: 2px;
  height: 100px;
  margin: 50px auto 0;
  background-color: #fff;
  position: absolute;
  top: -150px;
  left: 0;
  -webkit-animation: arrow 2.5s ease 0s infinite normal;
  animation: arrow 2.5s ease 0s infinite normal; }

@keyframes arrow {
  0% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0); }
  60% {
    -webkit-transform: translate3d(-50%, 100px, 0);
    transform: translate3d(-50%, 100px, 0); }
  100% {
    -webkit-transform: translate3d(-50%, 100px, 0);
    transform: translate3d(-50%, 100px, 0); } }

@media screen and (max-width: 414px) {
  .arrowWrap {
    left: -4%; } }

.bannerWrap {
  position: -webkit-sticky;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100; }

.bannerWrap a:hover {
  opacity: .8; }

@media screen and (max-width: 768px) {
  .bannerWrap {
    right: 10px;
    width: 60%; } }

.banner-link {
  background-color: #ebf8ff;
  padding: 0 3rem 6rem 3rem; }
  .banner-link .inner {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    border-bottom: 1px solid #0081cd;
    padding-bottom: 20px; }
    .banner-link .inner:last-child {
      border-bottom: none;
      padding-bottom: 0; }
  .banner-link .parts {
    padding: 2rem 3rem;
    width: 50%; }
  .banner-link .parts-note {
    padding: 0 3rem; }
    @media screen and (max-width: 768px) {
      .banner-link .parts-note {
        padding: 0; } }
  .banner-link .detail {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    position: relative;
    display: flex;
    align-items: center; }
    .banner-link .detail::before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      background: #0081cd;
      height: 28px;
      width: 6px; }
  .banner-link .txt {
    margin-bottom: 2rem; }
  .banner-link .bannerLink-img .img-banner img {
    width: 100%; }
  .banner-link .bannerLink-img .img-banner--01 {
    width: 320px; }
  .banner-link .bannerLink-img .img-banner--02 {
    width: 290px; }
  .banner-link .bannerLink-img .img-banner--03 {
    width: 320px; }
  .banner-link .bannerLink-img .img-banner--04 {
    width: 255px; }
  .banner-link .bannerLink-img .img-banner--05 {
    width: 420px; }
  .banner-link .bannerLink-img .img-banner--06 {
    width: 420px; }
  .banner-link .bannerLink-img .img-banner--07 {
    width: 488px; }

.category {
  text-align: center;
  font-size: 1.8rem;
  padding-top: 40px; }
  .category--top {
    padding-top: 0; }

@media screen and (max-width: 768px) {
  .banner-link {
    padding: 0 .75rem 3rem .75rem; }
    .banner-link .inner {
      display: block;
      margin-left: 0; }
    .banner-link .parts {
      padding: 0 0 2rem 0;
      width: 100%; }
    .banner-link .txt {
      margin-bottom: 0; }
    .banner-link .bannerLink-img .img-banner img {
      max-width: 100%;
      margin: 0 auto;
      padding: 20px; }
    .banner-link .bannerLink-img .img-banner--01 {
      width: 100%; }
    .banner-link .bannerLink-img .img-banner--02 {
      width: 100%; }
    .banner-link .bannerLink-img .img-banner--03 {
      width: 100%; }
    .banner-link .bannerLink-img .img-banner--04 {
      width: 100%; }
    .banner-link .bannerLink-img .img-banner--05 {
      width: 100%; }
    .banner-link .bannerLink-img .img-banner--06 {
      width: 100%; }
    .banner-link .bannerLink-img .img-banner--07 {
      width: 100%; }
    .banner-link .category {
      font-size: 1.4rem;
      padding: 20px 0; }
      .banner-link .category--top {
        padding-top: 0; } }

@media screen and (min-width: 768px) and (max-width: 999px) {
  .banner-link .inner {
    display: block;
    margin-left: 0; }
  .banner-link .parts {
    padding: 0 0 2rem 0;
    width: 100%; } }

/* ---------------------- Mission ---------------------- */
#mission .inner {
  background: url("../img/bg-mission-pc.jpg") left center no-repeat;
  background-size: cover; }

#mission .mission-title .is-size-5 span:nth-of-type(2) {
  font-size: 1.05rem; }

#mission .columns {
  padding: 10rem 3rem; }

.mission-title {
  width: 62%;
  margin: 0 0 0 14rem;
  letter-spacing: .15em; }

.mission-title .is-size-1 {
  line-height: 1.5;
  margin-bottom: 2rem; }

.mission-title .is-size-5 {
  line-height: 1.8; }

#mission .content-txt p {
  line-height: 2.1;
  margin-bottom: 1.5rem;
  letter-spacing: 1px; }

@media screen and (max-width: 1660px) {
  .mission-title {
    width: 90%;
    margin: 0 0 0 5rem; } }

@media screen and (max-width: 1280px) {
  .mission-title {
    width: 95%;
    margin: 0 0 0 1rem; } }

@media screen and (max-width: 768px) {
  #mission .inner {
    background: url("../img/bg-mission-sp.jpg") top center no-repeat;
    background-size: cover; }
  #mission .columns {
    padding: 4rem 2.75rem; }
  .mission-title {
    width: 100%; } }

@media screen and (max-width: 414px) {
  #mission .inner {
    background: url("../img/bg-mission-sp.jpg") bottom center no-repeat;
    background-size: cover; }
  #mission .columns {
    padding: 7.5rem 0; } }

/* scrolldown */
.cp_box *, .cp_box *:before, .cp_box *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.cp_box {
  position: relative; }

.cp_box label {
  position: absolute;
  z-index: 1;
  bottom: 0;
  width: 100%;
  height: 140px;
  /* グラデーションの高さ */
  cursor: pointer;
  text-align: center;
  /* 以下グラデーションは背景を自身のサイトに合わせて設定してください */
  background: linear-gradient(to bottom, rgba(250, 252, 252, 0) 0%, rgba(250, 252, 252, 0.95) 90%);
  transition: .3s; }

.cp_box input:checked + label {
  background: inherit;
  /* 開いた時にグラデーションを消す */ }

.cp_box label:after {
  line-height: 2.5rem;
  position: absolute;
  z-index: 2;
  bottom: 20px;
  left: 0;
  content: "Read More " ">";
  font-family: 'Hind', sans-serif;
  letter-spacing: 0.05em;
  color: #0081cd;
  border: 1px solid #0081cd;
  background-color: #fff;
  padding: 0 2rem;
  transition: .3s; }

.cp_box label:hover:after {
  border-color: #004098;
  color: #004098; }

.cp_box input {
  display: none; }

.cp_box .cp_container {
  overflow: hidden;
  height: 370px;
  transition: all .5s; }

.cp_box input:checked + label {
  /* display: none ; 閉じるボタンを消す場合解放 */ }

.cp_box input:checked + label:after {
  content: "Close " "✕";
  font-family: 'Hind', sans-serif; }

.cp_box input:checked ~ .cp_container {
  height: auto;
  padding-bottom: 80px;
  transition: all .5s; }

@media screen and (max-width: 1024px) {
  .cp_box .cp_container {
    height: 430px; } }

@media screen and (max-width: 768px) {
  .mission-title {
    width: 100%;
    margin: 0 0 2rem 0; }
  .cp_box label {
    height: 40%; }
  .cp_box label:after {
    left: 50%;
    bottom: 50px;
    transform: translateY(-50%) translateX(-50%); }
  .cp_box .cp_container {
    height: 400px; } }

@media screen and (max-width: 414px) {
  .cp_box label {
    bottom: -5%;
    height: 480px; }
  .cp_box .cp_container {
    height: 480px; }
  .cp_box label:after {
    bottom: 20px; } }

.grad-wrap {
  position: relative; }

.grad-wrap + .grad-wrap {
  margin-top: 40px; }

.grad-trigger {
  z-index: 2;
  position: absolute;
  right: 0;
  bottom: 170px;
  left: 0;
  margin: auto auto 1rem;
  padding: .5em 0;
  text-align: center;
  border: 1px solid #0081cd;
  border-color: #0081cd;
  border-radius: 0;
  color: #0081cd;
  background-color: #fff;
  width: 200px; }

.grad-trigger::after {
  content: "Read More >";
  /*クリック前のボタンのラベル*/
  font-family: 'Hind', sans-serif; }

.grad-trigger .fa {
  margin-right: .5em; }

.grad-item {
  position: relative;
  overflow: hidden; }

.grad-item p {
  line-height: 2.1;
  margin-bottom: 1.5rem;
  letter-spacing: 1px; }

.grad-item.is-hide {
  height: 460px;
  /*隠した後の高さ*/ }

.grad-item::before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100px;
  /*グラデーションで隠す高さ*/
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.9) 50%, #fff 100%);
  background: linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.9) 50%, #fff 100%); }

.grad-trigger.is-show {
  bottom: -5%; }

.grad-trigger.is-show::after {
  content: "Close " "✕";
  /*クリック後にボタンのラベルを変える*/
  font-family: 'Hind', sans-serif; }

.grad-trigger.is-show + .grad-item::before {
  display: none; }

#text-wrap {
  display: block;
  margin: 0 auto; }

.hide-text {
  display: none; }

.grade {
  z-index: 1;
  bottom: 0;
  width: 100%;
  height: 140px;
  cursor: pointer;
  text-align: center;
  background: linear-gradient(to bottom, rgba(250, 252, 252, 0) 0%, rgba(250, 252, 252, 0.95) 90%); }

#text-wrap button {
  border: 1px solid #ccc;
  border-color: #0081cd;
  border-radius: 0;
  color: #0081cd;
  background-color: #fff;
  padding: .5rem 1rem;
  font-size: 14px;
  font-family: 'Hind', sans-serif;
  width: 180px; }

button.readmore:after {
  content: "Read More >";
  display: block; }

button.readmore.on-click:after {
  content: "Close " "✕";
  font-family: 'Hind', sans-serif; }

.show-text p, .hide-text p {
  line-height: 2.1;
  margin-bottom: 1.5rem;
  letter-spacing: 1px; }

/* ---------------------- access ---------------------- */
#access p {
  line-height: 2.1; }

.googlemap iframe {
  width: 980px;
  height: 489px;
  margin: 0 auto 2rem auto;
  display: block; }

@media screen and (max-width: 1024px) {
  .googlemap iframe {
    width: 96%; } }

@media screen and (max-width: 768px) {
  .googlemap iframe {
    width: 100%; } }

/* ---------------------- バナー ---------------------- */
#bnr-area {
  margin-top: 12px; }

#bnr-area a {
  transition: .3s; }

#bnr-area a img {
  width: 100%; }

#bnr-area a:hover {
  opacity: .8; }

@media screen and (max-width: 768px) {
  #bnr-area {
    margin-top: 0; }
  #bnr-area img {
    display: block; } }

/* ---------------------- contact ---------------------- */
#contact {
  color: #fff; }

#contact a {
  transition: .3s; }

#contact a:hover {
  opacity: .6; }

#contact img {
  width: 65%; }

#contact .column:first-of-type {
  margin-right: 2rem; }

@media screen and (max-width: 834px) {
  #contact img {
    width: 100%; } }

@media screen and (max-width: 768px) {
  #contact img {
    width: 80%;
    margin: 0 auto;
    display: block; }
  #contact .column:first-of-type {
    margin-right: 0;
    margin-bottom: .5rem; } }

@media screen and (max-width: 414px) {
  #contact img {
    width: 100%;
    margin: 0; } }

/* ---------------------- footer ---------------------- */
.footer {
  padding: 1rem 1.5rem 1rem;
  background-color: #fff; }

.footer nav {
  margin-bottom: 2rem; }

.footer nav a {
  color: #565656; }

.footer nav li:not(last-of-type) {
  margin-right: 1rem; }

.footer-logo-area img {
  width: 9%;
  margin: 0 auto 2rem auto; }

@media screen and (max-width: 768px) {
  .footer {
    padding: 2rem 1.5rem 2rem; }
  .footer-logo-area img {
    width: 20%;
    margin: 0 auto 2rem auto; } }

@media screen and (max-width: 414px) {
  .footer-logo-area img {
    width: 40%; } }

/* ---------------------- <<<<< Company >>>>> ---------------------- */
.bg-company {
  background: url("../img/img-company-mv-pc.jpg") center center no-repeat;
  background-size: cover; }

#business h3.subtitle-02 {
  margin-bottom: 4rem; }

#business p.text-area {
  margin-bottom: 2rem; }

#business img {
  width: 50%; }

#business ul {
  margin-left: 0; }

#business .content li {
  margin-top: 1.25em;
  padding-bottom: 1.25em;
  border-bottom: 2px solid #cfcfcf; }

#business .column {
  padding: 1rem; }

#company-info a {
  text-decoration: underline;
  transition: .3s; }

@media screen and (max-width: 1024px) {
  #business img {
    width: 70%; } }

@media screen and (max-width: 768px) {
  #business .content {
    margin-bottom: 3.5rem; }
  #business .columns {
    padding: 0 .7rem; }
  #business .column:first-of-type {
    margin-bottom: 2rem; } }

@media screen and (max-width: 414px) {
  #business img {
    width: 100%; }
  #business .column {
    padding: 0; } }

/* ---------------------- <<<<< Message >>>>> ---------------------- */
.bg-message {
  background: url("../img/img-message-mv-pc.jpg") center center no-repeat;
  background-size: cover; }

/* 代表取締役 中原祐治 */
#yujinakahara .inner {
  width: 1200px;
  margin: 0 auto; }

#yujinakahara .column {
  padding: 1.5rem; }

#yujinakahara .txt-area {
  letter-spacing: 1px;
  line-height: 1.8;
  margin-bottom: 4rem; }

.career-area {
  font-size: .875rem !important; }

.career-area.content .table th, .career-area.content .table td {
  padding: 1em .75em !important; }

@media screen and (max-width: 1200px) {
  #yujinakahara .inner {
    width: 100%; } }

@media screen and (max-width: 834px) {
  #yujinakahara .columns:not(.is-desktop) {
    display: initial; }
  #yujinakahara .column {
    padding: 0; }
  #yujinakahara .column.is-hidden-desktop img {
    margin-bottom: 1rem; } }

@media screen and (max-width: 768px) {
  .career-area.content .table th {
    width: 100%;
    border: none !important;
    padding: 1.5em .75em 0 !important; }
  .career-area.content .table tr:first-of-type th {
    padding-top: 0 !important; }
  .career-area.content .table td {
    padding: .75em .75em 1.5em !important; } }

/* お客様との約束 */
#promise.section {
  padding: 6rem 0; }

#promise .columns.is-gapless:not(:last-child) {
  margin-bottom: 0; }

#promise .column {
  padding: 0; }

#promise .column img {
  width: 100%;
  display: block; }

#promise .column.promise-bg-01 {
  background: url("../img/bg-message-01.png") center center no-repeat;
  background-size: cover;
  position: relative; }

#promise .column.promise-bg-02 {
  background: url("../img/bg-message-02.png") center center no-repeat;
  background-size: cover;
  position: relative; }

#promise .column.promise-bg-03 {
  background: url("../img/bg-message-03.png") center center no-repeat;
  background-size: cover;
  position: relative; }

#promise .column .txt-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin: auto;
  width: 100%;
  letter-spacing: 1px;
  line-height: 2.1; }

#promise .columns:nth-child(odd) .txt-box, #promise .columns:nth-child(odd) .txt-box h3.subtitle-03.font-color-01 {
  color: #fff !important; }

#promise .columns:nth-child(odd) .txt-box h3.subtitle-03:before {
  background-color: #fff; }

@media screen and (max-width: 834px) {
  #promise .columns:not(.is-desktop) {
    display: initial; }
  #promise .column.promise-bg-01, #promise .column.promise-bg-02, #promise .column.promise-bg-03 {
    height: 536px; }
  #promise .column .txt-box p {
    text-align: left !important;
    padding: 0 3rem; } }

@media screen and (max-width: 768px) {
  #promise.section {
    padding: 4rem 0; } }

@media screen and (max-width: 414px) {
  #promise .column.promise-bg-01, #promise .column.promise-bg-02, #promise .column.promise-bg-03 {
    height: auto; }
  #promise .column .txt-box {
    position: initial;
    transform: none;
    margin: 0;
    padding: 3rem .75rem; }
  #promise h3.subtitle-03 {
    margin-bottom: 2rem; }
  h3.subtitle-03::before {
    bottom: -15px; }
  #promise .column .txt-box p {
    padding: 0; } }

/* ---------------------- <<<<< Contact >>>>> ---------------------- */
.label {
  color: #565656; }

input {
  font-family: "Sawarabi Gothic"; }

#contact-form .tag:not(body) {
  border-radius: 0; }

.field:not(:last-child) {
  margin-bottom: 1.75rem; }

/* ---------------------- <<<<< Contact確認画面 >>>>> ---------------------- */
.error_messe {
  color: #f14668; }

#formWrap .sub-conts-area th, #formWrap .sub-conts-area td {
  padding: 2em 1.75em !important; }

#formWrap .sub-conts-area th {
  font-weight: normal;
  width: 20%;
  border-bottom: 2px solid #004098 !important; }

@media screen and (max-width: 768px) {
  #formWrap .sub-conts-area th, #formWrap .sub-conts-area td {
    display: block; }
  #formWrap .sub-conts-area th {
    width: 100%;
    border: none !important;
    padding: 1.5em .75em 0 !important; }
  #formWrap .sub-conts-area td {
    padding: .75em .75em 1.5em !important; } }

/* ---------------------- <<<<< FD宣言 >>>>> ---------------------- */
#sub-hero.fd h2 {
  line-height: 1.4; }

#fd .text-area-inner {
  margin-bottom: 1.4rem; }

#fd .text-area-inner__red {
  color: red; }

#fd .content h4 {
  margin-bottom: .4em; }

#fd .bg-supplement-area-01 {
  margin-top: 3.9999em; }

@media screen and (max-width: 414px) {
  #fd .bg-supplement-area-01 {
    margin-top: 3em; } }

#fd .bg-supplement-area-01 .text-area-inner p:first-of-type {
  margin-bottom: .5em; }

#fd .table-wrap .fd-table {
  font-size: 14px; }

#fd .content ol li span {
  border-bottom: 1px solid #004098; }

#fd .table-wrap .fd-table tr td {
  border-right: 1px solid #dbdbdb;
  vertical-align: middle;
  text-align: left; }

#fd .table-wrap .fd-table tr th {
  border-right: 1px solid #dbdbdb; }

#fd .table-wrap .fd-table tr .bg-blue {
  background-color: #004098;
  color: #fff;
  font-size: 16px;
  vertical-align: middle;
  text-align: center; }

#fd .table-wrap .fd-table tr .item {
  width: 25%; }

#fd .table-wrap .fd-table tr .date-1 {
  width: 6%; }

#fd .table-wrap .fd-table tr .date-2 {
  width: 8%; }

#fd .table-wrap .fd-table tr .date-3 {
  width: 14%; }

#fd .table-wrap .fd-table tr .date-4 {
  width: 40%; }

#fd .table-wrap .fd-table tr .line-blue {
  border-bottom: 1px solid #004098;
  border-left: 1px solid #dbdbdb;
  vertical-align: middle;
  text-align: left; }

#fd .table-wrap .fd-table tr .line-gray {
  border-bottom: 1px solid #dbdbdb;
  border-left: 1px solid #dbdbdb;
  vertical-align: middle;
  text-align: left; }

#fd .table-wrap .fd-table tr .line-dot {
  border-bottom: 1px dotted #dbdbdb; }

#fd .table-wrap .fd-table tr .line-dot-w4 {
  width: 4.5%; }

#fd .table-wrap .fd-table tr .line-dot-w5 {
  width: 5%; }

#fd .table-wrap .fd-table tr .line-dot-w6 {
  width: 6%; }

#fd .table-wrap .fd-table tr .line-dot-w8 {
  width: 8%; }

#fd .table-wrap .fd-table tr .line-dot-w11 {
  width: 11%; }

#fd .table-wrap .fd-table tr .line-dot.line-dot-center {
  text-align: center; }

#fd .table-wrap .fd-table tr .txt-l {
  text-align: left; }

#fd .table-wrap .fd-table tr .txt-sm {
  font-size: 10px; }

#fd .table-wrap .fd-table tr:last-child td {
  border-bottom: 1px solid #dbdbdb; }

#fd .table-wrap .table-memo {
  font-size: 12px;
  text-align: right; }

#fd a {
  word-break: break-all; }

#fd .table-wrap .fd-table tr td:empty {
  background-image: linear-gradient(to top left, transparent, transparent 49%, #dbdbdb 1px, #dbdbdb 1px, transparent 50%, transparent); }

#fd .pattern-02 li {
  list-style-type: none;
  counter-increment: cnt;
  padding-left: 2em;
  text-indent: -2em; }

#fd .pattern-02 li::before {
  content: "(" counter(cnt) ") "; }

@media screen and (max-width: 768px) {
  #fd .table-wrap {
    overflow-x: scroll; }
  #fd .table-wrap .fd-table {
    width: 1000px;
    border-collapse: collapse;
    table-layout: fixed; }
  #fd .table-wrap .table-memo {
    font-size: 12px;
    text-align: left; }
  #fd .table-wrap .fd-table tr .txt-sm {
    font-size: 14px; } }

.inquiry {
  width: 360px;
  margin: 0 0 10px auto; }
  .inquiry .inquiry-ttl {
    font-size: 12px;
    margin-bottom: 0px; }
  .inquiry .inquiry-list {
    display: flex;
    font-size: 12px;
    margin-bottom: 0px !important; }
    .inquiry .inquiry-list dt {
      width: 40px; }
    .inquiry .inquiry-list dd {
      margin-left: 1em; }

/* ---------------------- <<<<< privacy >>>>> ---------------------- */
#privacy .p-indent {
  padding-left: 1em;
  text-indent: -1em; }

#privacy .p-txt-right {
  text-align: right; }

.sns-area-wrap {
  margin-bottom: 50px; }

.sns-area {
  max-width: 400px;
  margin: 0 auto;
  border-bottom: 1px solid #0281CD;
  border-top: 1px solid #0281CD;
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media screen and (max-width: 768px) {
    .sns-area {
      max-width: 272px;
      padding: 15px 30px; } }
  .sns-area__tlt {
    color: #565656;
    font-size: 14px; }
    @media screen and (max-width: 768px) {
      .sns-area__tlt {
        font-size: 12px; } }
  .sns-area__list {
    display: flex;
    justify-content: space-between;
    width: 220px; }
    @media screen and (max-width: 768px) {
      .sns-area__list {
        width: 130px; } }
    .sns-area__list li {
      width: 50px; }
      @media screen and (max-width: 768px) {
        .sns-area__list li {
          width: 30px; } }
      .sns-area__list li img {
        width: 100%; }

.second-item {
  display: block;
  padding: .6rem;
  color: #ffffff;
  background-color: #004098;
  transition: .3s;
  cursor: pointer; }
  @media screen and (max-width: 768px) {
    .second-item {
      font-size: 3.8vw;
      width: 100%;
      color: #565656;
      background-color: #ffffff !important; } }
  @media screen and (max-width: 414px) {
    .second-item {
      font-size: 1.4rem; } }
  .second-item:hover {
    color: #ffffff !important;
    background-color: #1f67cc;
    transition: .3s; }
  .second-item__wrap {
    position: absolute;
    z-index: 999;
    color: #ffffff;
    background-color: #004098; }
    @media screen and (max-width: 768px) {
      .second-item__wrap {
        display: none !important;
        position: relative;
        color: #004098;
        background-color: #ffffff !important; } }
    .second-item__wrap:hover {
      display: block !important; }
    .second-item__wrap.is-active {
      display: block !important; }

.navbar {
  width: 100%;
  padding: 0 0 0 6rem;
  margin: 0 !important; }
  @media screen and (max-width: 768px) {
    .navbar {
      padding: 0 6rem; } }

.nav-toggle-btn {
  cursor: default !important; }
  .nav-toggle-btn:hover {
    opacity: 1 !important; }
  @media screen and (max-width: 768px) {
    .nav-toggle-btn {
      cursor: pointer !important; } }

#index-header .navbar-brand {
  padding: 0 6rem;
  top: 0 !important; }
  @media screen and (max-width: 768px) {
    #index-header .navbar-brand {
      padding: 0; } }

.front-logo.is-active img {
  width: 60px !important; }
  @media screen and (max-width: 768px) {
    .front-logo.is-active img {
      width: 50px !important; } }

header #navbarMenuHeroC a.navbar-item {
  color: #004098;
  white-space: normal;
  padding: 1.2rem .8rem;
  background-color: #ffffff;
  display: block !important;
  margin: 0 auto;
  transition: .3s;
  height: 100%;
  cursor: pointer; }
  @media screen and (max-width: 768px) {
    header #navbarMenuHeroC a.navbar-item {
      font-size: 5.2vw;
      max-width: auto;
      padding: .8rem;
      background-color: #004098 !important;
      color: #ffffff; } }
  @media screen and (max-width: 414px) {
    header #navbarMenuHeroC a.navbar-item {
      font-size: 1.4rem; } }
  header #navbarMenuHeroC a.navbar-item.is-over {
    background-color: #004098 !important;
    color: #ffffff; }
  header #navbarMenuHeroC a.navbar-item:hover {
    transition: 86ms;
    background-color: #004098 !important;
    color: #ffffff;
    height: 100%;
    display: inline-block; }
    @media screen and (max-width: 768px) {
      header #navbarMenuHeroC a.navbar-item:hover {
        opacity: 1;
        background-color: #004098; } }
  header #navbarMenuHeroC a.navbar-item__icon {
    position: relative; }
    @media screen and (max-width: 768px) {
      header #navbarMenuHeroC a.navbar-item__icon:before {
        position: absolute;
        content: "";
        top: 50%;
        right: 20px;
        width: .8rem;
        height: .8rem;
        border-top: 1px solid #ffffff;
        border-right: 1px solid #ffffff;
        transform: translateY(-50%) rotate(45deg); } }

.navbar-item__main, .navbar-item__sub {
  display: block; }

.navbar-item__main {
  font-size: .5rem;
  line-height: .8rem; }
  @media screen and (max-width: 768px) {
    .navbar-item__main {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: inherit; } }

.navbar-item__sub {
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-weight: 700; }
  @media screen and (max-width: 768px) {
    .navbar-item__sub {
      font-size: .5rem;
      line-height: inherit; } }

.navbar-end {
  display: block;
  width: 100%;
  z-index: 9998;
  background: #ffffff;
  display: flex;
  flex-flow: wrap;
  z-index: 99;
  min-height: 4.5rem; }
  @media screen and (max-width: 834px) {
    .navbar-end {
      text-align: left !important; } }
  @media screen and (max-width: 768px) {
    .navbar-end {
      position: fixed;
      left: 0;
      top: 0;
      padding: 5rem 2rem;
      overflow: scroll;
      display: block;
      height: 100%; } }
  @media screen and (max-width: 768px) {
    .navbar-end > * {
      width: 100%; } }

.navbar-menu {
  flex-grow: 1;
  flex-shrink: 0;
  background: #ffffff;
  height: 100%;
  box-sizing: border-box; }
  @media screen and (max-width: 768px) {
    .navbar-menu {
      display: none; } }
  @media screen and (max-width: 768px) {
    .navbar-menu.is-active {
      display: block; } }

.navbar-burger {
  display: none;
  position: relative;
  margin-right: 0;
  height: 4.4rem;
  z-index: 100; }
  @media screen and (max-width: 768px) {
    .navbar-burger {
      display: block; } }

.navbar-brand {
  flex-flow: wrap;
  position: relative;
  z-index: 9999; }
  @media screen and (max-width: 768px) {
    .navbar-brand {
      width: 100%; } }

.nav-toggle__icon {
  position: relative; }
  @media screen and (max-width: 768px) {
    .nav-toggle__icon:before, .nav-toggle__icon:after {
      position: absolute;
      content: "";
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      width: 1rem;
      height: 1px;
      background-color: #ffffff; } }
  @media screen and (max-width: 768px) {
    .nav-toggle__icon:before {
      transform: translateY(-50%) rotate(90deg);
      transition: 86ms; } }
  @media screen and (max-width: 768px) {
    .nav-toggle__icon.is-active:before {
      transform: translateY(-50%) rotate(0);
      opacity: 0;
      transition: 86ms; } }

.header-logo-area {
  position: relative;
  z-index: 100; }

@media screen and (max-width: 768px) {
  .nav-item__wrap {
    border-bottom: 1px solid #ffffff; } }

html.is-fixed {
  position: relative;
  overflow: hidden !important; }

body.is-fixed {
  position: relative;
  overflow: hidden !important; }

.hero {
  z-index: 29; }

.footer-banner-area img {
  width: 30vw;
  max-width: 300px;
  min-width: 180px; }
  @media screen and (max-width: 768px) {
    .footer-banner-area img {
      width: 70vw;
      max-width: 70vw;
      min-width: 70vw; } }

.footer-logo-area img {
  margin: 0 2rem 0 0 !important;
  width: 10vw;
  max-width: 150px;
  min-width: 50px;
  height: 100%; }
  @media screen and (max-width: 768px) {
    .footer-logo-area img {
      margin: 0 0 2rem 0 !important;
      width: 40vw;
      max-width: 40vw;
      min-width: 40vw; } }

.footer-links {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  margin: 2rem 0; }
  @media screen and (max-width: 768px) {
    .footer-links {
      flex-direction: column; } }

.column__marginTop {
  margin-top: 40px; }
  @media screen and (max-width: 768px) {
    .column__marginTop {
      margin-top: 4rem; } }

.column__logo {
  width: 170px;
  min-height: 60px;
  display: flex;
  flex-flow: wrap;
  align-items: center; }
  @media screen and (max-width: 768px) {
    .column__logo {
      margin: 0 auto 20px auto;
      max-width: 60%; } }
  .column__logo img {
    width: 120px !important;
    margin: 0 auto; }
  .column__logo--small01 img {
    width: 75px !important; }
  .column__logo--small02 img {
    width: 90px !important; }
  .column__logo--big01 {
    width: 180px; }
    .column__logo--big01 img {
      width: 160px !important; }
  .column__logo--big02 img {
    width: 165px !important; }
  .column__logo--axa img {
    width: 130px !important; }
  .column__logo--axadilect img {
    width: 145px !important; }
  .column__logo--nn img {
    width: 160px !important; }
  .column__logo--fwd img {
    width: 98px !important; }
  .column__logo--orix img {
    width: 148px !important; }
  .column__logo--gib img {
    width: 132px !important; }
  .column__logo--sony img {
    width: 130px !important; }
  .column__logo--somp img {
    width: 140px !important; }
  .column__logo--frontier img {
    width: 140px !important; }
  .column__logo--zurich img {
    width: 100px !important; }
  .column__logo--zurich img {
    width: 100px !important; }
  .column__logo--seemee img {
    width: 150px !important; }
  .column__logo--nanairo img {
    width: 150px !important; }
  .column__logo--nihonseimei img {
    width: 135px !important; }
  .column__logo--neofirst img {
    width: 130px !important; }
  .column__logo--hanasaku img {
    width: 145px !important; }
  .column__logo--manulife img {
    width: 160px !important; }
  .column__logo--mitsui img {
    width: 150px !important; }
  .column__logo--meijiyasuda img {
    width: 140px !important; }
  .column__logo--metlife img {
    width: 160px !important; }
  .column__logo--medicare img {
    width: 130px !important; }
  .column__logo--aioinissei img {
    width: 145px !important; }
  .column__logo--mitsuikaijyo img {
    width: 150px !important; }
  .column__logo--tokyokaijjyo img {
    width: 145px !important; }
  .column__logo--sonpojapan img {
    width: 165px !important; }
  .column__logo--ps img {
    width: 130px !important; }

.column__item {
  display: flex;
  flex-flow: wrap;
  align-items: center; }
  @media screen and (max-width: 768px) {
    .column__item {
      display: block; } }

.bg-client {
  background: url(../img/img-client-mv-pc.jpg) center center no-repeat;
  background-size: cover; }

.bg-diallife {
  background: url(../img/img-diallife-mv-pc.jpg) center center no-repeat;
  background-size: cover; }

.bg-dialnonlife {
  background: url(../img/img-dialnonlife-mv-pc.jpg) center center no-repeat;
  background-size: cover; }

.bg-dialmini {
  background: url(../img/img-dialmini-mv-pc.jpg) center center no-repeat;
  background-size: cover; }

.title--bold {
  font-weight: bold; }

.title__box {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  margin-top: 60px; }
  @media screen and (max-width: 768px) {
    .title__box {
      display: block; } }
  @media screen and (max-width: 768px) {
    .title__box {
      padding-bottom: 1.2rem;
      border-bottom: 2px solid #004098;
      margin-bottom: 2rem; } }

.title__logo {
  width: 20%; }
  .title__logo img {
    width: 180px;
    margin: 0 auto;
    display: block; }
    @media screen and (max-width: 768px) {
      .title__logo img {
        display: block;
        width: 70%;
        margin: 0 auto; } }
  .title__logo--small01 img {
    width: 110px; }
  .title__logo--small02 img {
    width: 130px; }
  .title__logo--big01 img {
    width: 220px; }
  @media screen and (max-width: 768px) {
    .title__logo {
      margin: 0 auto 20px auto;
      max-width: 70%;
      width: 100%; } }

.title__text {
  width: 80%;
  padding-left: 1.75rem; }
  @media screen and (max-width: 768px) {
    .title__text {
      width: 100%;
      padding-left: 0; } }

.icon-area {
  margin: 0 auto 50px; }
  @media screen and (max-width: 768px) {
    .icon-area {
      padding: 0 .75rem; } }
  .icon-area__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center; }
    @media screen and (max-width: 768px) {
      .icon-area__list {
        justify-content: space-between; } }
    .icon-area__list li {
      margin: 40px 20px 0;
      width: 10%; }
      .icon-area__list li img {
        width: 100%; }
      .icon-area__list li a {
        width: 100%; }
        .icon-area__list li a:hover {
          opacity: 0.7; }
      @media screen and (max-width: 768px) {
        .icon-area__list li {
          width: calc((100% - 40px)/2);
          margin: 40px 0 0 0; } }
    .icon-area__list .text-link {
      width: 200px; }
      @media screen and (max-width: 768px) {
        .icon-area__list .text-link {
          width: calc((100% - 40px)/2); } }

.order {
  padding: 0 0 1rem 0;
  font-size: 0.875rem; }

@media screen and (max-width: 768px) {
  .table__title {
    display: block; } }

.table__title02 {
  display: block; }

.table__list__title {
  width: 100px;
  display: inline-block; }

.table-mb {
  margin-bottom: 120px !important; }
  @media screen and (max-width: 768px) {
    .table-mb {
      margin-bottom: 3rem !important; } }

.privacy-list .indent {
  text-indent: -1em;
  padding-left: 1em; }

.policy-ttl {
  position: relative;
  display: block;
  margin-left: 20px; }
  .policy-ttl:before {
    content: '';
    position: absolute;
    left: -20px;
    background: #565656;
    color: #fff;
    width: 10px;
    height: 100%;
    text-align: center; }

/* ニュースティッカー */
.newsTicker {
  position: absolute;
  bottom: 20px;
  background: rgba(217, 217, 217, 0.65);
  border-radius: 0 20px 20px 0;
  z-index: 30;
  padding: 10px 14px 10px 14px; }

.newsTicker-link {
  display: block; }

.newsTicker-image {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 23px;
  margin-top: 6px; }

.newsTicker-image-marinos {
  display: block;
  width: 65px; }

.newsTicker-image-grulla {
  display: block;
  width: 92px; }

.newsTicker-text {
  color: #000000;
  font-size: 10px;
  margin-top: 8px; }

/* CTAボタン */
.cta-button {
  background: linear-gradient(90deg, #1853df 8.66%, #03d5ee 96.04%);
  border-radius: 30px;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 6px 6px 6px 12px;
  width: fit-content;
  position: fixed;
  bottom: 20px;
  right: 10px;
  z-index: 30; }

.cta-button__text {
  margin: 0; }

.cta-button__image {
  width: 27px;
  height: 27px; }

.footer-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem; }

.footer-banner__item {
  flex: 1;
  width: 70vw;
  max-width: 70vw;
  min-width: 70vw; }

@media screen and (min-width: 768px) {
  .newsTicker {
    padding: 14px 24px 14px 24px; }
  .newsTicker-title {
    font-size: 14px; }
  .newsTicker-image-marinos {
    width: 75px; }
  .newsTicker-image-grulla {
    width: 112px; }
  .newsTicker-text {
    font-size: 12px;
    margin-top: 10px; }
  .cta-button {
    font-size: 14px;
    padding: 8px 8px 8px 18px;
    transition: 0.2s;
    right: 15px; }
  .cta-button:hover {
    color: #ffffff;
    background: linear-gradient(90deg, #1853df 50%, #03d5ee 96.04%); }
  .cta-button__image {
    width: 35px;
    height: 35px; }
  .footer-banner {
    flex-direction: row; }
  .footer-banner__item {
    margin-bottom: 0;
    width: 30vw;
    max-width: 300px;
    min-width: 180px; } }
