.privacy-list {
  .indent {
    text-indent: -1em;
    padding-left: 1em;
  }
}
.policy-ttl {
  position: relative;
  display: block;
  margin-left: 20px;
  &:before {
    content:'';
    position: absolute;
    left: -20px;
    background: #565656;
    color: #fff;
    width: 10px;
    height: 100%;
    text-align: center;
  }
}
