@charset "UTF-8";

/* ニュースティッカー */

.newsTicker {
    position: absolute;
    bottom: 20px;
    background: rgba(217, 217, 217, .65);
    border-radius: 0 20px 20px 0;
    z-index: 30;
    padding: 10px 14px 10px 14px;
}

.newsTicker-link {
    display: block;
}

.newsTicker-image {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 23px;
    margin-top: 6px;
}

.newsTicker-image-marinos {
    display: block;
    width: 65px;
}

.newsTicker-image-grulla {
    display: block;
    width: 92px;
}

.newsTicker-text {
    color: #000000;
    font-size: 10px;
    margin-top: 8px;
}

/* CTAボタン */

.cta-button {
    background: linear-gradient(90deg, #1853df 8.66%, #03d5ee 96.04%);
    border-radius: 30px;
    font-size: 10px;
    color: #ffffff;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 6px 6px 6px 12px;
    width: fit-content;
    position: fixed;
    bottom: 20px;
    right: 10px;
    z-index: 30;
}

.cta-button__text {
    margin: 0;
}

.cta-button__image {
    width: 27px;
    height: 27px;
}

.footer-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.footer-banner__item {
    flex: 1;
    width: 70vw;
    max-width: 70vw;
    min-width: 70vw;
}

@media screen and (min-width: 768px) {
    .newsTicker {
        padding: 14px 24px 14px 24px;
    }

    .newsTicker-title {
        font-size: 14px;
    }

    .newsTicker-image-marinos {
        width: 75px;
    }

    .newsTicker-image-grulla {
        width: 112px;
    }

    .newsTicker-text {
        font-size: 12px;
        margin-top: 10px;
    }

    .cta-button {
        font-size: 14px;
        padding: 8px 8px 8px 18px;
        transition: 0.2s;
        right: 15px;
    }

    .cta-button:hover {
        color: #ffffff;
        background: linear-gradient(90deg, #1853df 50%, #03d5ee 96.04%);
    }

    .cta-button__image {
        width: 35px;
        height: 35px;
    }

    .footer-banner {
        flex-direction: row;
    }

    .footer-banner__item {
        margin-bottom: 0;
        width: 30vw;
        max-width: 300px;
        min-width: 180px;
    }
}