.title {
  &--bold {
    font-weight: bold;
  }
  &__box {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    @include sp {
      display: block;
    }
    margin-top: 60px;
    @include sp {
      padding-bottom: 1.2rem;
      border-bottom: 2px solid #004098;
      margin-bottom: 2rem;
    }
  }
  &__logo {
    width: 20%;
    img {
      width: 180px;
      margin: 0 auto;
      display: block;
      @include sp {
        display: block;
        width: 70%;
        margin: 0 auto;
      }
    }
    &--small01 {
      img {
        width: 110px;
      }
    }
    &--small02 {
      img {
        width: 130px;
      }
    }
    &--big01 {
      img {
        width: 220px;
      }
    }
    @include sp {
      margin: 0 auto 20px auto;
      max-width: 70%;
      width: 100%;
    }
  }
  &__text {
    width: 80%;
    padding-left: 1.75rem;
    @include sp {
      width: 100%;
      padding-left: 0;
    }
  }
}

.icon-area{
  margin: 0 auto 50px;
  @include sp {
    padding: 0 .75rem;
  }
  &__list{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    @include sp {
      justify-content: space-between;
    }
    li{
      margin: 40px 20px 0;
      // width: 13%;
      width: 10%;
      img{
        width: 100%;
      }
      a{
        width: 100%;
        &:hover{
          opacity: 0.7;
        }
      }
      @include sp {
        width: calc((100% - 40px)/2);
        margin: 40px 0 0 0;
      }
    }
    .text-link{
      width: 200px;
      @include sp {
        width: calc((100% - 40px)/2);
      }
    }
  }
}
.order{
  padding: 0 0 1rem 0;
  font-size: 0.875rem;
}
