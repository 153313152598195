.bg {
    &-client {
        background: url(../img/img-client-mv-pc.jpg) center center no-repeat;
        background-size: cover;
    }
    &-diallife {
        background: url(../img/img-diallife-mv-pc.jpg) center center no-repeat;
        background-size: cover;
    }
    &-dialnonlife {
        background: url(../img/img-dialnonlife-mv-pc.jpg) center center no-repeat;
        background-size: cover;
    }
    &-dialmini {
        background: url(../img/img-dialmini-mv-pc.jpg) center center no-repeat;
        background-size: cover;
    }
}